









import { Component } from 'vue-property-decorator'
import ShowMessages from '@/components/forms/ShowMessages.vue'
import MetaComponent from '@/common/MetaComponent.vue'
@Component({
  components: { ShowMessages }
})
export default class MessagingReceivedMessages extends MetaComponent {
}
